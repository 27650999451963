/**
 * Helper function for exhaustive checks of discriminated unions in TypeScript.
 *
 * Example:
 * ```ts
 * type A = {type: 'a'};
 * type B = {type: 'b'};
 * type Union = A | B;
 *
 * function doSomething(arg: Union) {
 *   if (arg.type === 'a') {
 *     return something;
 *   }
 *
 *   if (arg.type === 'b') {
 *     return somethingElse;
 *   }
 *
 *   // TS will error if there are other types in the union.
 *   // Will throw an Error when called at runtime.
 *   return assertNever(arg);
 * }
 * ```
 */
export function assertNever(
    value: never,
    message: string = 'Unhandled discriminated union member'
): never {
    throw new Error(`${message}: ${JSON.stringify(value)}`);
}

/**
 * Assert that a value is defined.
 */
export function assertIsDefined<T>(
    value: T,
    message: string = `${value} is not defined`
): asserts value is NonNullable<T> {
    if (value === undefined || value === null) {
        throw new Error(message);
    }
}

/**
 * Assert that a value is undefined.
 */
export function assertIsUndefined<T>(
    value: T | null | undefined
): asserts value is null | undefined {
    if (value !== undefined && value !== null) {
        throw new Error(`${value} is defined`);
    }
}

/**
 * The type of an item of an array
 */
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

/**
 * Type to make optional properties on a object mandatory.
 *
 * interface SomeObject {
 *   uid: string;
 *   price: number | null;
 *   location?: string;
 * }
 *
 * type ValuableObject = MandateProps<SomeObject, 'price' | 'location'>;
 */
export type MandateProps<T extends {}, K extends keyof T> = T & {
    [MK in K]-?: NonNullable<T[MK]>;
};

/**
 * Filter function to exclude `null` values
 */
export function filterOutNullable<T>(value: T): value is NonNullable<T> {
    return !!value;
}

/**
 * Distributive version of Omit that can work on an union of interfaces.
 * See https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 * and https://stackoverflow.com/questions/57103834/typescript-omit-a-property-from-all-interfaces-in-a-union-but-keep-the-union-s
 */
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

/**
 * Helper function to create an array containing every and all of the given union type.
 * https://github.com/microsoft/TypeScript/issues/53171
 *
 * Usage:
 * const create = arrayOfAll<'a' | 'b' | 'c'>();
 * create(['a', 'b']); // Invalid, missing c
 * create(['a', 'b', 'c', 'd']); // Invalid, extra element
 * create(['a', 'b', 'c']); // OK
 *
 * Shorthand:
 * arrayOfAll<'a' | 'b' | 'c'>()(['a', 'b', 'c'])
 */
export const arrayOfAll =
    <UnionType>() =>
    <U extends UnionType[]>(
        array: U & ([UnionType] extends [U[number]] ? unknown : 'Invalid')
    ): Array<UnionType> =>
        array;

/**
 * A deep version of Partial. Allows nested objects to also be partial.
 *
 * interface Obj {
 *   nested: {
 *     prop1: string;
 *     prop2: string;
 *   }
 * }
 *
 * const partial: Partial<Obj> = { nested: { prop1: 'prop1' } };  // invalid, missing prop2
 * const deep: DeepPartial<Obj> = { nested: { prop1: 'prop1' } };  // OK, nested is partial too
 */
export type DeepPartial<K> = {
    [attr in keyof K]?: K[attr] extends object
        ? DeepPartial<K[attr]>
        : K[attr] extends object | null
        ? DeepPartial<K[attr]> | null
        : K[attr] extends object | null | undefined
        ? DeepPartial<K[attr]> | null | undefined
        : K[attr];
};

/**
 * Given a type TObject that extends an object, enforce that the keys of another type ObjectToPrevent
 * are optional and can only ever have a value of undefined.
 */
export type PreventKeys<TObject extends object, ObjectToPrevent extends object> = {
    [PreventedKey in Exclude<keyof ObjectToPrevent, keyof TObject>]?: never;
} & {
    [Key in keyof TObject]: TObject[Key];
};

/**
 * An object can either be of one type or another but never both.
 * Similar to a discriminated union but without having to rely on
 * a literal member to discriminate between the two.
 *
 * @example
 * ```typescript
 * type OldOrNew = Either<
 *     { theOldWay: string; id: number; },
 *     { theNewWay: string; id: string; }
 * >;
 * const oldObj: OldOrNew = { theOldWay: 'hello', id: 1 };
 * const newObj: OldOrNew = { theNewWay: 'world', id: '2' };
 * const badObj: OldOrNew = { theOldWay: 'hello', theNewWay: 'world', id: 3 }; // Error
 * ```
 */
export type Either<LeftObj extends object, RightObj extends object> =
    | PreventKeys<LeftObj, RightObj>
    | PreventKeys<RightObj, LeftObj>;

/**
 * Given an object record type, it returns the type of its values.
 */
export type ValueTypes<T extends object> = T[keyof T];

/**
 * Type for a value that might be a promise or not.
 */
export type MaybePromise<T> = T | Promise<T>;

/**
 * Ensure an object of this type has at least one of the given keys defined.
 */
export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

/**
 * An array of at least one element of type T.
 */
export type ArrayOfAtLeastOne<T> = [T, ...T[]];

/**
 * Type guard to assert that `value` is a string.
 */
export function isString(value: unknown): value is string {
    return typeof value === 'string';
}

/**
 * Deep map a type to another type in an object.
 */
export type DeepMap<T, S, D> = {
    [K in keyof T]: T[K] extends S ? D : T[K] extends object ? DeepMap<T[K], S, D> : T[K];
};

/**
 * Validate a value against a tuple
 */
export function asArrayValue<T extends string, DefaultValue = undefined>(
    value: unknown,
    values: readonly T[],
    defaultValue: DefaultValue = undefined as DefaultValue
): T | DefaultValue {
    return values.includes(value as T) ? (value as T) : defaultValue;
}
